export const bookappoinmentDialog_style = {
  titleSx: {
    color: 'common.black',
    fontSize: '16px',
    fontWeight: '600',
  },
  profileSx: {
    display: 'flex',
    alignItems: 'center',
  },
  proPicSX: {
    mr: 1.75,
  },
  firstSx: {
    fontSize: '16px',
    fontWeight: '500',
  },
  subSx: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#808080',
  },
  dialogSx: {
    width: '650px',
    height: '550px',
  },

  inputSx: {
    mt: 3,
  },
  continueBtnSx: {
    display: 'flex',
    pt: 1.25,
  },
  saveBtnSx: {
    // mr: 1.25,
    width: '30px',
  },
  noBtnSx: {
    textTransform: 'none',
    backgroundColor: 'primaryTints.300',
    color: 'primary.main',
    boxShadow: 'none',
    width: '30px',
    '&:hover': {
      backgroundColor: 'primaryTints.300',
      boxShadow: 'none',
      width: '30px',
    },
  },
  buttonSx: {
    bgcolor: 'white',
    color: 'primary.main',
    borderRadius: '8px',
    '&:hover': {
      color: 'white',
    },
    border: '1px solid #007965',
  },
};
