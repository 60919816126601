import { Button, DateRangePicker, DialogModal } from '@atoms';
import { useDoctorAppointment, usePatientDetails } from '@hc/store';
import { formatDate } from '@hc/utils';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import { addMinutes } from 'date-fns';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { Dropdown } from '../../../atoms';
import { bookappoinmentDialog_style } from './style';
// import { useNavigate } from 'react-router-dom';

function BookappoinmentDialog(props) {
  const {
    className = '',
    handleClose = () => false,
    isModalOpen,
    rootStyle = {},
    patientData = {},
    setTabIndex = () => {},
    isMobileResponsive,
    ...rest
  } = props;

  const {
    getDoctorSlot,
    updatePatientState,
    setBookAppoinment,
    doctorSlots,
    patientState,
    clearDoctorSlots,
  } = usePatientDetails(
    (state) => ({
      patientState: state.patientState,
      setBookAppoinment: state.setBookAppoinment,
      doctorSlots: state.doctorSlots,
      updatePatientState: state.updatePatientState,
      getDoctorSlot: state.getDoctorSlot,
      clearDoctorSlots: state.clearDoctorSlots,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { getDoctorAppointmentData, getDashBoardData } = useDoctorAppointment(
    (state) => ({
      getDashBoardData: state.getDashBoardData,
      getDoctorAppointmentData: state.getDoctorAppointmentData,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const buttonData = [
    {
      label: 'Yes',
    },
    {
      label: 'No',
    },
  ];

  const { endDate } = patientState;
  const [dateVal, setDateVal] = useState();
  const [selectedDData, setSelectedData] = useState('');
  const [slotData, setSlotData] = useState([]);
  const [sendInvites, setSendInvite] = useState(1);
  const [active, setActive] = useState(false);
  const [isError, setIsError] = useState(false);

  const onConfirm = async () => {
    if (dateVal) {
      if (selectedDData && selectedDData?.length > 0) {
        const startDate = `${formatDate(
          endDate.toDateString(),
        )}T${selectedDData}+05:30`;
        const date = new Date(startDate);
        const dateEndTime = addMinutes(date, 15);

        const endTime = `${formatDate(
          endDate.toDateString(),
        )}T${dateEndTime.getHours()}:${dateEndTime.getMinutes()}:00+05:30`;

        const obj = {
          user_profile_id: patientData?.user_profile_id ?? '',
          patient_family_member_id: patientData?.family_member_id ?? '',
          other_member_id: patientData?.other_member_id ?? '',
          appointed_doctor_details: {
            appointment_date: `${formatDate(endDate.toDateString())}`,
            appointment_time_slot: selectedDData,
            sendInvite: active === true ? true : false,
            appointment_client_start_time: startDate,
            appointment_client_end_time: endTime,
          },
        };
        handleClose();
        clearDoctorSlots();
        await setBookAppoinment(obj);
        await getDoctorAppointmentData('DoctorAppoinment', date);
        await getDashBoardData('doctorDashBoard');
        setTabIndex('1');
        setIsError();
        setDateVal();
        setSelectedData('');
        setSendInvite(1);
        setActive();
      } else {
        // setIsError(true);
        toast.error('Please select the slot...!!!!');
      }
    } else {
      toast.error('Please select the date...!!!!');
    }
  };

  const onDateChange = async (val) => {
    setDateVal(val);
    updatePatientState('endDate', val);
    updatePatientState('startDate', val);
    await getDoctorSlot(val);
  };

  const handleAdd = (option) => {
    setSelectedData(option);
  };

  const handleOnchange = (event) => {
    const slotArrayCopy = JSON.parse(JSON.stringify(doctorSlots));
    const arr = [];
    if (slotArrayCopy?.length > 0) {
      slotArrayCopy.forEach((val) => {
        if (val?.label?.includes(event?.target?.value)) {
          arr.push(val);
        }
      });
    }

    setSlotData(arr);
  };

  const handleInvite = (index) => {
    setSendInvite(index);
    if (index === 0) {
      setActive(true);
    } else if (index === 1) {
      setActive(false);
    }
  };

  const handleCloseFnc = () => {
    setSendInvite(1);
    handleClose();
  };

  useEffect(() => {
    const date = new Date();
    onDateChange(date);
  }, []);

  return (
    <Box
      sx={{
        ...bookappoinmentDialog_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <DialogModal
        title="BOOK APPOINTMENT"
        titleStyle={bookappoinmentDialog_style.titleSx}
        isBackRequired={isMobileResponsive ? true : false}
        isCloseOut={isMobileResponsive ? false : true}
        isMobileResponsive={isMobileResponsive}
        maxModalWidth="xl"
        topDivider
        bottomDivider
        dialogRootStyle={
          isMobileResponsive ? '' : bookappoinmentDialog_style.dialogSx
        }
        content={
          <Box>
            <Box sx={bookappoinmentDialog_style.profileSx}>
              <Box sx={bookappoinmentDialog_style.proPicSX}>
                {' '}
                <Avatar />{' '}
              </Box>
              <Box sx={bookappoinmentDialog_style.rightSx}>
                <Typography sx={bookappoinmentDialog_style.firstSx}>
                  {patientData?.patient_profile?.name}
                </Typography>
                <Typography sx={bookappoinmentDialog_style.subSx}>
                  {patientData?.patient_profile?.gender}{' '}
                  {patientData?.patient_profile?.age} Years
                </Typography>
              </Box>
            </Box>

            <Grid container rowSpacing={3}>
              <Grid item xs={12} sx={bookappoinmentDialog_style.inputSx}>
                <Typography sx={bookappoinmentDialog_style.titleSx}>
                  When is the appointment?
                </Typography>
                <Grid container columnSpacing={2} sx={{ py: 1.25 }}>
                  <Grid item xs={6}>
                    <DateRangePicker
                      onChange={onDateChange}
                      startDate={dateVal ?? ''}
                      minDate={new Date()}
                      sx={{ p: 0 }}
                      customSx={{
                        height: '45px',
                        border: '1.5px solid',
                        borderColor: 'border.main',
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    {/* <SearchSelect
                      placeholder="select"
                      options={slotData.length > 0 ? slotData : doctorSlots}
                      optionViewKey="label"
                      onChange={handleAdd}
                      isError={isError ? true : false}
                      helperText={isError ? 'Please select the slot' : ''}
                      onChangeText={handleOnchange}
                    /> */}
                    <Dropdown
                      placeholder="select"
                      selectOption={
                        slotData.length > 0 ? slotData : doctorSlots
                      }
                      optionListStyle={{ height: '350px' }}
                      optionViewKey="label"
                      onChange={(event) => handleAdd(event.target.value)}
                      isError={isError ? true : false}
                      helperText={isError ? 'Please select the slot' : ''}
                      onChangeText={handleOnchange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ p: 0 }}>
                <Typography sx={bookappoinmentDialog_style.titleSx}>
                  Want to send a meeting invite to the patient?
                </Typography>
              </Grid>
              <Grid items>
                <Box sx={bookappoinmentDialog_style.continueBtnSx}>
                  {buttonData?.map((val, index) => (
                    <Box key={index} mr={1.5}>
                      <Button
                        onClick={() => handleInvite(index)}
                        buttonStyle={
                          sendInvites === index
                            ? bookappoinmentDialog_style.saveBtnSx
                            : bookappoinmentDialog_style.noBtnSx
                        }
                      >
                        {val?.label}
                      </Button>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        }
        actions={
          <Grid
            p={1}
            container
            spacing={2}
            justifyContent={isMobileResponsive ? 'space-around' : 'end'}
          >
            {isMobileResponsive && (
              <Grid item xs={6}>
                <Button
                  onClick={() => handleCloseFnc()}
                  sx={bookappoinmentDialog_style.buttonSx}
                >
                  CANCEL
                </Button>
              </Grid>
            )}

            <Grid item xs={isMobileResponsive ? 6 : 4}>
              <Button onClick={() => onConfirm()}>CONFIRM</Button>
            </Grid>
          </Grid>
        }
        isDialogOpened={isModalOpen}
        handleCloseDialog={() => handleCloseFnc()}
      />
    </Box>
  );
}

BookappoinmentDialog.propTypes = {
  className: PropTypes.string,
  handleClose: PropTypes.func,
  isModalOpen: PropTypes.func,
  patientData: PropTypes.object,
  rootStyle: PropTypes.object,
  setTabIndex: PropTypes.func,
  isMobileResponsive: PropTypes.bool,
};

export { BookappoinmentDialog };

