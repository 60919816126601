/* eslint-disable react/display-name */
import { doctorRoutes } from '@hc/routes';
import { localStorageKeys, loginRoutes } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import { useEffect, useState } from 'react';

import moment from 'moment';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

function PrivateRouter(props) {
  const { children } = props;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    // get the authToken from local storage
    const authToken = localStorage.getItem(localStorageKeys?.authToken);

    if (
      !authToken &&
      !loginRoutes
        .filter((route) => route !== '/onboarding')
        .some((route) => route === location?.pathname)
    ) {
      navigate(doctorRoutes.signin);
    }

    if (location.pathname === doctorRoutes.createaccount) {
      localStorage.clear();
    }
    const data = parseJwt(authToken);
    // If expire date is lesser than current date & time clear the auth token in local storage and redirect to login page
    if (data?.exp && moment().unix() >= data?.exp) {
      toast('Redirecting to the login page, please continue', { icon: '⚠️' });
      localStorage.clear();
      navigate(doctorRoutes.signin);
    }

    if (authToken && location.pathname === '/login') {
      navigate(doctorRoutes.home);
    }

    if (authToken && searchParams.get('token')?.length === 0) {
      // If expire date is lesser than current date & time clear the auth token in local storage and redirect to login page
      if (moment().unix() >= data?.exp) {
        toast('Redirecting to the login page, please continue', { icon: '⚠️' });
        localStorage.clear();
        navigate(doctorRoutes.signin);
      } else if (
        !data?.is_doctor_onboarded &&
        !data?.is_verified &&
        location.pathname === '/google_calender_integeration'
      ) {
        setShowComponent(true);
      } else if (!data?.is_doctor_onboarded) {
        navigate(doctorRoutes.onboarding);
      } else if (
        location.pathname !== doctorRoutes.resetpassword &&
        loginRoutes.some((route) => route === location?.pathname)
      ) {
        navigate(doctorRoutes.home);
      }
    }

    setShowComponent(true);
  }, [location]);

  if (showComponent) {
    return children;
  }
  return null;
}

export { PrivateRouter };
